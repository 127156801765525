import {Component, OnDestroy} from '@angular/core';
import {AuthenticationService} from "../../../service/authentication.service";
import packageInfo from 'package.json'
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import {first, map} from "rxjs";
import {RfaStore} from "../../../store/rfa/rfa.store";
import {EventService} from "../../../service/event.service";
import {MatDialog} from "@angular/material/dialog";
import {BasicDialogComponent} from "../basic-dialog/basic-dialog.component";
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RfaPickerComponent } from '../rfa-picker/rfa-picker.component';
import { NgIf } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import {P_DOCUMENTS} from "../../../app.routes";

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    standalone: true,
    imports: [MatToolbarModule, NgIf, RouterLink, RfaPickerComponent, MatButtonModule, MatIconModule, MatTooltipModule]
})
export class ToolbarComponent implements OnDestroy {
  APP_VERSION = packageInfo.version;

  detailView: boolean = false;
  showPublication: boolean = false;
  selectedRfaKuerzel?: string;

  routeSub$;
  rfaSub$;

  loggedInUser = () => this.authService.userName;

  constructor(private readonly authService: AuthenticationService, private dialog: MatDialog,
              private rfaStore: RfaStore, private activatedRoute: ActivatedRoute,
              private eventService: EventService, private route: Router) {

    //Dadurch kann bestimmt werden, ob man den Abmelden o. Veröffentlichen Button sehen möchte
    this.routeSub$ = this.activatedRoute.queryParams.subscribe({
      next: params => {
        const documentId = params['documentId'];
        const readonly = params['readonly'];
        this.detailView = documentId !== null && documentId !== undefined
        this.showPublication  = readonly === null || readonly === undefined || readonly === false;
      }
    });

    this.rfaSub$ = this.rfaStore.getSelectedRfa().pipe(map((rundfunkanstalt) => {
      if (rundfunkanstalt) this.selectedRfaKuerzel = rundfunkanstalt.kuerzel
    })).subscribe();
  }

  ngOnDestroy() {
    if (this.routeSub$)
      this.routeSub$.unsubscribe();
    if (this.rfaSub$)
      this.rfaSub$.unsubscribe();
  }

  showActions = () => this.eventService.showPublishActions(true);

  logout = (): void => this.authService.logOut();

  returnBack = () => this.route.navigate([`${P_DOCUMENTS}/${this.selectedRfaKuerzel}`]);

  openReturnDialog() {
    this.dialog.open(BasicDialogComponent, {
      width: '450px',
      data: {
        dialogData: {
          title: 'Wettertext verwerfen',
          message: 'Sind Sie sicher, dass Sie zurückkehren wollen? Alle ungespeicherten Änderungen gehen verloren',
          confirmText: 'Ja ich bin mir sicher',
          cancelText: 'Nein ich möchte hier bleiben'
        }
      }
    }).afterClosed().pipe(first()).subscribe({
      next: (value) => {
        if (value) this.returnBack().then(console.log).catch(console.error);
      }
    })
  }
}
