import {ApplicationConfig, importProvidersFrom} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalService
} from "@azure/msal-angular";
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from "@angular/material/snack-bar";
import {MatDialogModule} from "@angular/material/dialog";
import {
  NGX_MAT_DATE_FORMATS,
  NgxMatDateFormats,
  NgxMatNativeDateModule
} from "@angular-material-components/datetime-picker";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {FormsModule} from "@angular/forms";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {MatListModule} from "@angular/material/list";
import {MatTooltipModule} from "@angular/material/tooltip";
import {provideRouter, withDisabledInitialNavigation, withEnabledBlockingInitialNavigation} from "@angular/router";
import {APP_ROUTES} from "./app.routes";
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {provideAnimations} from "@angular/platform-browser/animations";
import {STEPPER_GLOBAL_OPTIONS} from "@angular/cdk/stepper";
import {StatusCodes} from "./model/constants";
import {DatePipe} from "@angular/common";
import {BrowserUtils} from "@azure/msal-browser";
import {MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory} from "../msal.config";

const initialNavigation = !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
  ? withEnabledBlockingInitialNavigation() // Set to enabledBlocking to use Angular Universal
  : withDisabledInitialNavigation();


const INTL_DATE_INPUT_FORMAT = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hourCycle: 'h24',
  hour: '2-digit',
  minute: '2-digit',
  timeZone: 'Europe/Berlin'
};

const CUSTOM_MAT_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: INTL_DATE_INPUT_FORMAT,
  },
  display: {
    dateInput: INTL_DATE_INPUT_FORMAT,
    monthYearLabel: {year: 'numeric', month: 'short'},
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'},

  },
};


export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(BrowserModule, MsalModule, MatSnackBarModule, MatDialogModule, NgxMatNativeDateModule, MatToolbarModule, MatIconModule, MatButtonModule, MatFormFieldModule, MatSelectModule, FormsModule, MatProgressSpinnerModule, MatDatepickerModule, MatInputModule, MatNativeDateModule, MatListModule, MatTooltipModule,),
    provideRouter(APP_ROUTES, initialNavigation),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}},
    {provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}},
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MAT_DATE_FORMATS},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    StatusCodes,
    DatePipe,
  ]
}
